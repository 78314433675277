.logo {
  color: #f5eedcff;
  font-size: 15vmin;
}

.link {
  /* border-radius: 2em;
  border: 0.1em solid #f5eedcff;
  width:auto; */
}

.instructions {
  width: 33%;
  margin: auto;
  left: 50%;
  font-size: 3vmin;
  padding: 6px;
}

.App-logo {
  animation: App-logo-spin infinite 40s linear;
  width: 12vmin;
  pointer-events: none;
  z-index: 2;
  position: absolute;
  /* padding: 1vmin; */
}
.logoSpace {
  width: 12vmin;
  height: 12vmin;
  display:inline-block;
  pointer-events: none;
  z-index: 2;
  position: relative;
  left: -6vmin;
  /* padding: 1vmin; */
}

@keyframes waveUpDown {
        0% { }
        50% { transform: translateY(-20px); }
        100% { }
}
