body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wave1 {
  -webkit-animation: waveUpDown infinite 5s linear;
          animation: waveUpDown infinite 5s linear;
  width: 50px;
  height: 20px;
  bottom:0;
  left:0;
  padding:5px;
  position: relative;
}

.wave2 {
  -webkit-animation: waveUpDown infinite 5s linear;
          animation: waveUpDown infinite 5s linear;
  width: 50px;
  height: 20px;
  bottom:20px;
  left:50px;
  padding:5px;
  position: relative;
}

.wave3 {
  -webkit-animation: waveUpDown infinite 5s linear;
          animation: waveUpDown infinite 5s linear;
  width: 50px;
  height: 20px;
  bottom:0px;
  left:100px;
  padding:5px;
  position: relative;
}

.waveGroup {
  margin-top: -100px;
  margin-left: -100px;
  position: absolute;
  padding:40px;
  z-index: 1;

}

@-webkit-keyframes waveUpDown {
        0% { }
        50% { -webkit-transform: translateY(-20px); transform: translateY(-20px); }
        100% { }
}

@keyframes waveUpDown {
        0% { }
        50% { -webkit-transform: translateY(-20px); transform: translateY(-20px); }
        100% { }
}

.logo {
  color: #f5eedcff;
  font-size: 15vmin;
}

.link {
  /* border-radius: 2em;
  border: 0.1em solid #f5eedcff;
  width:auto; */
}

.instructions {
  width: 33%;
  margin: auto;
  left: 50%;
  font-size: 3vmin;
  padding: 6px;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 40s linear;
          animation: App-logo-spin infinite 40s linear;
  width: 12vmin;
  pointer-events: none;
  z-index: 2;
  position: absolute;
  /* padding: 1vmin; */
}
.logoSpace {
  width: 12vmin;
  height: 12vmin;
  display:inline-block;
  pointer-events: none;
  z-index: 2;
  position: relative;
  left: -6vmin;
  /* padding: 1vmin; */
}

@-webkit-keyframes waveUpDown {
        0% { }
        50% { -webkit-transform: translateY(-20px); transform: translateY(-20px); }
        100% { }
}

@keyframes waveUpDown {
        0% { }
        50% { -webkit-transform: translateY(-20px); transform: translateY(-20px); }
        100% { }
}

.App {
  text-align: center;
}

.App-header {
  background-color: #34bacc;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f5eedcff;
}

.iframe{
  margin-bottom:3vh;
}


.error {
  left: 50%;
  font-size: 3vmin;
  padding: 6px;
  color: #d67379ff;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  0% {
    -webkit-transform: rotate(0deg) scale(.9,.9);
            transform: rotate(0deg) scale(.9,.9);
    z-index: 1;

   }
  50% {
    -webkit-transform: rotate(180deg) scale(1.1,1.1);
            transform: rotate(180deg) scale(1.1,1.1);
    z-index: 1;
   }
  100% {
    -webkit-transform: rotate(360deg) scale(.9,.9);
            transform: rotate(360deg) scale(.9,.9);
    z-index: 1;
   }
}

@keyframes App-logo-spin {
  0% {
    -webkit-transform: rotate(0deg) scale(.9,.9);
            transform: rotate(0deg) scale(.9,.9);
    z-index: 1;

   }
  50% {
    -webkit-transform: rotate(180deg) scale(1.1,1.1);
            transform: rotate(180deg) scale(1.1,1.1);
    z-index: 1;
   }
  100% {
    -webkit-transform: rotate(360deg) scale(.9,.9);
            transform: rotate(360deg) scale(.9,.9);
    z-index: 1;
   }
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

body {
  background-color: #333;
  color: #eee;
  font-family: Helvetica, Arial;
  font-size: 3vmin;
  font-weight: bold;
  overflow: hidden;

}

.hidden {
  display: none;
}

.poolInput{
  text-decoration: none;
  background-color: #f5eedcff;
  border-radius: 2em;
  border: 0.2em solid #f5eedcff;

  width: 20em;
  font-size: 3vmin;
  padding: 0.7em 1.5em;

}

.padded-div{
  padding: 3em;
}

.padded-25{
  padding: 25px;
}

/** Buttons **/

.btn {
  /* margin: auto;
  left: 50%;
  margin-left: -10em;
  width: 30%; */
  text-decoration: none;
  background-color: #d67379ff;
  border-radius: 2em;
  border: 0.2em solid #d67379ff;
  font-weight: bold;
  color: #f5eedcff;
  width: 20em;
  cursor: pointer;
  font-size: 3vmin;
  padding: 0.7em 1.5em;
  text-transform: uppercase;
  transition: all 0.25s ease;
  z-index: 1;
  /* position: absolute; */
}

.btn-2 {
  /* margin: auto; */
  /* left: 50%; */
  /* margin-left: -10em; */
  /* width: 30%; */
  text-decoration: none;
  background-color: #f5b84cff;
  border-radius: 2em;
  border: 0.2em solid #f5b84cff;
  font-weight: bold;
  color: #f5eedcff;
  width: 20em;
  cursor: pointer;
  font-size: 3vmin;
  padding: 0.7em 1.5em;
  text-transform: uppercase;
  transition: all 0.25s ease;
  z-index: 1;
  /* position: absolute; */
}

.btn:hover {
  background: #d16168;
  color: #f5eedcff;
}

.btn--login {
  margin: 0 auto;
}

/** Background **/

.background {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  -webkit-filter: blur(8em) opacity(0.6);
          filter: blur(8em) opacity(0.6);
  position: absolute;
}

.main-wrapper {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  margin: 0 auto;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  width: 80%;
  z-index: 1;
}

.container {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
}

.main-container {
  -webkit-flex: 1 1;
          flex: 1 1;
}

