.wave1 {
  animation: waveUpDown infinite 5s linear;
  width: 50px;
  height: 20px;
  bottom:0;
  left:0;
  padding:5px;
  position: relative;
}

.wave2 {
  animation: waveUpDown infinite 5s linear;
  width: 50px;
  height: 20px;
  bottom:20px;
  left:50px;
  padding:5px;
  position: relative;
}

.wave3 {
  animation: waveUpDown infinite 5s linear;
  width: 50px;
  height: 20px;
  bottom:0px;
  left:100px;
  padding:5px;
  position: relative;
}

.waveGroup {
  margin-top: -100px;
  margin-left: -100px;
  position: absolute;
  padding:40px;
  z-index: 1;

}

@keyframes waveUpDown {
        0% { }
        50% { transform: translateY(-20px); }
        100% { }
}
