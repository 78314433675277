.App {
  text-align: center;
}

.App-header {
  background-color: #34bacc;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f5eedcff;
}

.iframe{
  margin-bottom:3vh;
}


.error {
  left: 50%;
  font-size: 3vmin;
  padding: 6px;
  color: #d67379ff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg) scale(.9,.9);
    z-index: 1;

   }
  50% {
    transform: rotate(180deg) scale(1.1,1.1);
    z-index: 1;
   }
  100% {
    transform: rotate(360deg) scale(.9,.9);
    z-index: 1;
   }
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

body {
  background-color: #333;
  color: #eee;
  font-family: Helvetica, Arial;
  font-size: 3vmin;
  font-weight: bold;
  overflow: hidden;

}

.hidden {
  display: none;
}

.poolInput{
  text-decoration: none;
  background-color: #f5eedcff;
  border-radius: 2em;
  border: 0.2em solid #f5eedcff;

  width: 20em;
  font-size: 3vmin;
  padding: 0.7em 1.5em;

}

.padded-div{
  padding: 3em;
}

.padded-25{
  padding: 25px;
}

/** Buttons **/

.btn {
  /* margin: auto;
  left: 50%;
  margin-left: -10em;
  width: 30%; */
  text-decoration: none;
  background-color: #d67379ff;
  border-radius: 2em;
  border: 0.2em solid #d67379ff;
  font-weight: bold;
  color: #f5eedcff;
  width: 20em;
  cursor: pointer;
  font-size: 3vmin;
  padding: 0.7em 1.5em;
  text-transform: uppercase;
  transition: all 0.25s ease;
  z-index: 1;
  /* position: absolute; */
}

.btn-2 {
  /* margin: auto; */
  /* left: 50%; */
  /* margin-left: -10em; */
  /* width: 30%; */
  text-decoration: none;
  background-color: #f5b84cff;
  border-radius: 2em;
  border: 0.2em solid #f5b84cff;
  font-weight: bold;
  color: #f5eedcff;
  width: 20em;
  cursor: pointer;
  font-size: 3vmin;
  padding: 0.7em 1.5em;
  text-transform: uppercase;
  transition: all 0.25s ease;
  z-index: 1;
  /* position: absolute; */
}

.btn:hover {
  background: #d16168;
  color: #f5eedcff;
}

.btn--login {
  margin: 0 auto;
}

/** Background **/

.background {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  filter: blur(8em) opacity(0.6);
  position: absolute;
}

.main-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  position: relative;
  width: 80%;
  z-index: 1;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.main-container {
  flex: 1;
}
